import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mt-3 mb-5" }, "Admin Settings", -1)),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent), {
      selectUserTable: _ctx.selectUserTable,
      selectAddUser: _ctx.selectAddUser
    }, null, 8, ["selectUserTable", "selectAddUser"]))
  ]))
}