import {IGeneralInfo} from "@/types/IGeneralInfo";
import {defineComponent} from "vue";

export default defineComponent({
  data() {
    return {
      matterName: "",
      generalInfoInputs: {
        "Email": "",
        "Insurance Company Name": "",
        "Address": "",
        "Adjuster Name": "",
        "Adjuster Salutation": "",
        "Phone": "",
        "Client Name": "",
        "Defendant Name": "",
        "Claim Number": "",
        "Incident Date": "",
        "Afforded in PIP": 0,
        "Damage": 0,
      },
      isGeneralInfoFetched: false,
    };
  },

  methods: {
    setGeneralInfo(generalInfo: IGeneralInfo) {
      const matterInfo = generalInfo.matterInfo;
      const damage = generalInfo.damage;

      this.isGeneralInfoFetched = true;

      this.matterName = matterInfo.matterName;
      this.generalInfoInputs["Email"] = matterInfo.email;
      this.generalInfoInputs["Insurance Company Name"] = matterInfo.insuranceCompanyName;
      this.generalInfoInputs["Address"] = matterInfo.address;
      this.generalInfoInputs["Adjuster Name"] = matterInfo.adjusterName;
      this.generalInfoInputs["Adjuster Salutation"] = matterInfo.adjusterSalutation;
      this.generalInfoInputs["Phone"] = matterInfo.phone;
      this.generalInfoInputs["Client Name"] = matterInfo.clientName;
      this.generalInfoInputs["Defendant Name"] = matterInfo.defendantName;
      this.generalInfoInputs["Claim Number"] = matterInfo.claimNumber;
      this.generalInfoInputs["Incident Date"] = matterInfo.incidentDate;
      this.generalInfoInputs["Afforded in PIP"] = matterInfo.affordedInPip;
      this.generalInfoInputs["Damage"] = damage;
    }
  },
});
