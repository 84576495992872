import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container text-start" }
const _hoisted_2 = {
  key: 0,
  class: "container text-danger fw-bold text-start my-1"
}
const _hoisted_3 = { class: "mb-3 col-3" }
const _hoisted_4 = { class: "mb-3 col-3" }
const _hoisted_5 = { class: "mb-3 col-3" }
const _hoisted_6 = { class: "mb-3 col-3" }
const _hoisted_7 = { class: "col-3 d-flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "row align-items-start" }, [
      _createElementVNode("div", { class: "col-12 my-3" }, [
        _createElementVNode("h4", { class: "text-start" }, "Add new User")
      ])
    ], -1)),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.error) + " ", 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.error = null)),
            type: "button",
            class: "btn-close btn-danger pt-0"
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createUserRequest && _ctx.createUserRequest(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("label", {
          for: "exampleInputEmail1",
          class: "form-label fw-bold"
        }, "Email address", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
          type: "email",
          class: "form-control",
          id: "exampleInputEmail1",
          "aria-describedby": "emailHelp",
          placeholder: "Email",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.email]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("label", {
          for: "exampleInputEmail1",
          class: "form-label fw-bold"
        }, "First Name", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName) = $event)),
          type: "text",
          class: "form-control",
          id: "exampleInputEmail1",
          "aria-describedby": "emailHelp",
          placeholder: "First Name",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.firstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[9] || (_cache[9] = _createElementVNode("label", {
          for: "exampleInputEmail1",
          class: "form-label fw-bold"
        }, "Last Name", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName) = $event)),
          type: "text",
          class: "form-control",
          id: "exampleInputEmail1",
          "aria-describedby": "emailHelp",
          placeholder: "Last Name",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.lastName]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("label", {
          for: "exampleInputPassword1",
          class: "form-label fw-bold"
        }, "Password", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event)),
          type: "password",
          class: "form-control",
          id: "exampleInputPassword1",
          placeholder: "Password",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.password]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[11] || (_cache[11] = _createElementVNode("button", {
          type: "submit",
          class: "btn btn-success"
        }, "Add User", -1)),
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.selectUserTable && _ctx.selectUserTable(...args))),
          type: "button",
          class: "btn btn-danger"
        }, "Back to Active Users")
      ])
    ], 32)
  ]))
}