<template>
  <input v-model="inputValue" class="me-3 my-1" type="password" placeholder="New Password">
  <button @click="changeUserPassword" type="button" class="btn btn-success btn-sm mx-2">
    Update
  </button>
  <button @click="deactivateComponentFunc()" type="button" class="btn btn-danger btn-sm mx-2">
    Close
  </button>
</template>

<script lang="ts">
import { updatePassword } from "@/common/api";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  name: "AdminTableChangePasswordButton",

  props: {
    recordId: {
      type: Number,
      required: true,
    },
    deactivateComponentFunc: {
      type: Function,
      required: true,
    },
    reloadAdminTable: {
      type: Function,
      required: true,
    },
    recreateTableFunc: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      inputValue: "",
    };
  },

  methods: {
    async changeUserPassword() {
      try {
        await updatePassword(this.recordId, this.inputValue);
      } catch (error) {
        const message = "Failed to update password:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }

      this.deactivateComponentFunc();
      this.recreateTableFunc();
    },
  },
});
</script>
