export const loginUrl = "/login";
export const sessionUrl = "/session";
export const userUrl = "/user";
export const fetchMatterInfoUrl = "/matter-info";
export const validateTokenUrl = "/validate-token";

export const GENERAL_INFO_STATUS = "GENERAL INFO";
export const OCR_PROCESSING_STATUS = "OCR PROCESSING";
export const OCR_PROCESSING_FAILED_STATUS = "OCR PROCESSING FAILED";
export const RETRIEVAL_PROCESSING_STATUS = "RETRIEVAL PROCESSING";
export const RETRIEVAL_PROCESSING_FAILED_STATUS = "RETRIEVAL PROCESSING FAILED";
export const GENERATOR_PROCESSING_STATUS = "GENERATOR PROCESSING";
export const GENERATOR_PROCESSING_FAILED_STATUS = "GENERATOR PROCESSING FAILED";
export const COMPLETED_STATUS = "COMPLETED";

export const statusMap = {
  [GENERAL_INFO_STATUS]: "General Information",
  [OCR_PROCESSING_STATUS]: "Processing OCR",
  [OCR_PROCESSING_FAILED_STATUS]: "OCR Failed",
  [RETRIEVAL_PROCESSING_STATUS]: "Retrieving Data",
  [RETRIEVAL_PROCESSING_FAILED_STATUS]: "Data Retrieval Failed",
  [GENERATOR_PROCESSING_STATUS]: "Generating Demand Letter",
  [GENERATOR_PROCESSING_FAILED_STATUS]: "Demand Letter Generation Failed",
  [COMPLETED_STATUS]: "Completed",
} as const;

export type StatusKey = keyof typeof statusMap;

export const STATUS_CODE_UNAUTHORIZED = 401;

export const RESEND_REQUEST_TIMEOUT = 60000;
