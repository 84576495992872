import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mb-2 fw-bold" }, "Delete this user?", -1)),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteUser && _ctx.deleteUser(...args))),
      type: "button",
      class: "btn btn-danger mx-2"
    }, " Delete "),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deactivateComponentFunc())),
      type: "button",
      class: "btn btn-success mx-2"
    }, " Cancel ")
  ], 64))
}