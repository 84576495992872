import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid user-profile-container pt-4" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_component = _resolveComponent("navbar-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_navbar_component),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.profileTab)))
        ])
      ])
    ])
  ], 64))
}