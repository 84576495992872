<template>
  <navbar-component />
  <main class="container-xxl demand-letter-container pt-4 pb-4">
    <matter-submit
        v-if="!isGeneralInfoFetched"
        @submit="handleMatterSubmit"
    />
    <matter-name :matter-name="matterName"/>
    <general-info
        v-if="isGeneralInfoFetched"
        :matterName="matterName"
        :inputsData="generalInfoInputs"
    />
  </main>
</template>

<script lang="ts">
import NavbarComponent from "@/components/Navbar.vue"
import MatterSubmit from "@/components/MatterSubmit.vue";
import GeneralInfo from "@/components/GeneralInfo.vue";
import MatterName from "@/components/MatterName.vue";
import generalInfoMixin from "@/mixins/generalInfoMixin";
import { defineComponent } from "vue";
import { IMatterInfo } from "@/types/IMatterInfo";

export default defineComponent({
  name: "NewSession",

  components: {
    MatterName,
    NavbarComponent,
    GeneralInfo,
    MatterSubmit,
  },

  mixins: [generalInfoMixin],

  methods: {
    handleMatterSubmit(matterInfo: IMatterInfo) {
      if (matterInfo) {
        const generalInfo = {
          matterInfo,
          damage: 0,
        };

        this.setGeneralInfo(generalInfo);
      }
    },
  },
});
</script>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

p {
  margin: 0;
  font-size: 16px;
  color: #333;
}
</style>
