import DropdownButton from "@/components/DropdownButton.vue";
import { getUser } from "@/common/api";
import {Router, RouteLocationNormalized} from "vue-router";
import { statusMap, StatusKey } from "@/common/constants";
import {AxiosError} from "axios";

export const logout = async (router: Router) => {
  localStorage.clear();
  await router.push("/login");
};

export const checkIsAdmin = async () => {
  try {
    const response = await getUser();
    const user = response.data;
    return user.isAdmin;
  } catch (error) {
    const message = "Failed to get user data:";
    if (error instanceof Error) {
      console.error(message, error.message);
    } else if (error instanceof AxiosError) {
      console.error(message, error.response?.data)
    }
  }
};

export const getNavigationButtons = async (router: Router) => {
  const navigationButtons = [
    {
      component: DropdownButton,
      props: {
        buttonText: "Profile Settings",
        buttonClasses: "text-dark",
        handler: function () {
          router.push("/user-profile?tab=settings");
        },
      }
    },
  ];

  const isAdmin = await checkIsAdmin();

  if (isAdmin) {
    navigationButtons.push(
      {
        component: DropdownButton,
        props: {
          buttonText: "Admin Settings",
          buttonClasses: "text-dark",
          handler: function () {
            router.push("/user-profile?tab=admin");
          },
        }
      },
    )
  }

  navigationButtons.push(
    {
      component: DropdownButton,
      props: {
        buttonText: "Logout",
        buttonClasses: "text-danger",
        handler: async function () {
          await logout(router);
        }
      }
    },
  )

  return navigationButtons;
};

export const defineUserProfileTab = (route: RouteLocationNormalized) => {
  const tab = route.query.tab;
  switch (tab) {
    case "settings":
      return "UserProfileCommonSettings";
    case "admin":
      return "UserProfileAdminSettings";
    default:
      return "UserProfileSessionsList";
  }
};

export const getMappedStatus = (status: StatusKey) => {
  return statusMap[status] || "Unknown Status";
}
