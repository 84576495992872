import { validateToken } from "@/common/api";
import {AxiosError} from "axios";

class TokenService {
  static getToken() {
    return localStorage.getItem("token");
  }

  static setToken(token: string) {
    localStorage.setItem("token", token);
  }

  static async validateToken() {
    const token = this.getToken();

    if (!token) return false;

    try {
      const response = await validateToken(token);
      return response.data.valid;
    } catch (error) {
      const message = "Failed to validate token:";
      if (error instanceof Error) {
        console.error(message, error.message);
      } else if (error instanceof AxiosError) {
        console.error(message, error.response?.data)
      }
      return false;
    }
  }
}

export default TokenService;
