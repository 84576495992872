import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-3 w-25 m-auto" }
const _hoisted_3 = { class: "mb-3 w-25 m-auto" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_general_info = _resolveComponent("user-general-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "mb-4" }, "User Settings", -1)),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_user_general_info, {
            email: _ctx.user?.email || '',
            firstName: _ctx.user?.firstName || '',
            lastName: _ctx.user?.lastName || ''
          }, null, 8, ["email", "firstName", "lastName"]),
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateUser && _ctx.updateUser(...args)), ["prevent"])),
            class: "mt-5"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _cache[3] || (_cache[3] = _createElementVNode("label", {
                for: "exampleInputEmail1",
                class: "form-label fw-bold"
              }, "New Email", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newEmail) = $event)),
                type: "email",
                class: "form-control",
                id: "exampleInputEmail1",
                "aria-describedby": "emailHelp"
              }, null, 512), [
                [_vModelText, _ctx.newEmail]
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _cache[4] || (_cache[4] = _createElementVNode("label", {
                for: "exampleInputPassword1",
                class: "form-label fw-bold"
              }, "New Password", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
                type: "password",
                class: "form-control",
                id: "exampleInputPassword1"
              }, null, 512), [
                [_vModelText, _ctx.newPassword]
              ])
            ]),
            _createElementVNode("button", {
              disabled: !_ctx.newEmail && !_ctx.newPassword,
              class: "btn btn-success",
              type: "submit"
            }, "Update Account", 8, _hoisted_4)
          ], 32)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}