import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.matterName)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", {
          class: _normalizeClass([{'border': _ctx.sessionId}, "text-start fs-4 fw-medium border-success border-opacity-75 border-5 rounded p-3"])
        }, "Matter name: " + _toDisplayString(_ctx.matterName), 3)
      ]))
    : _createCommentVNode("", true)
}