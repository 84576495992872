<template>
  <div class="container matter-info-container">
    <div class="container">
      <div>
        <h5 class="text-start fw-bold mb-4">Enter the Matter name from Salesforce, e.g., MAT-12345678910:</h5>
        <form class="container d-flex justify-content-start ">
          <div class=" text-start row col-4 col-lg-3 col-xl-2 col-xxl-2 col-md-4 col-sm-4 col-">
            <input v-model="matterName" type="text" class="form-control fw-medium border-black"
                   placeholder="MAT-12345678910">
          </div>

          <div class="row col-4 col-lg-2 col-xl-2 col-xxl-2 col-md-3 col-sm-4 ms-5">
            <button v-if="!loading" @click="submit" :disabled="!matterName"
                    class="btn btn-secondary fw-bold">Submit
            </button>
            <button v-else class="btn btn-secondary fw-bold" type="button" disabled>
              <span class="spinner-border spinner-border-sm me-3" aria-hidden="true"></span>
              <span role="status">Loading...</span>
            </button>
          </div>
        </form>
      </div>

      <div v-if="error" class="text-start fw-bold mb-4 mt-2 text-danger"> {{ error }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { fetchMatterInfo } from "@/common/api";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  name: "MatterSubmit",

  data() {
    return {
      loading: false,
      matterName: "",
      error: "" as string | Error,
    };
  },

  methods: {
    async fetchMatterInfo() {
      this.loading = true;

      try {
        const response = await fetchMatterInfo(this.matterName);

        if (response.status === 200) {
          this.loading = false;
          if (!response.data.matterName) {
            this.error = "There's no such Matter name. Please make sure you enter an existing Matter name and try again.";
            return;
          }
          return response.data;
        }
      } catch (error) {
        const message = "Failed to fetch matter info:";
        if (error instanceof Error) {
          this.error = error;
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          this.error = error;
          console.error(message, error.response?.data)
        }
      }
    },

    async submit(event: Event) {
      event.preventDefault();

      this.error = "";

      if (!this.isFormValid()) {
        this.error = "Invalid Matter name. Please enter a valid Matter name in the format MAT-12345678910.";
        return;
      }

      const matterInfo = await this.fetchMatterInfo();

      if (matterInfo) {
        this.matterName = matterInfo.matterName;
        this.$emit("submit", matterInfo);
      }
    },

    isFormValid() {
      const pattern = /^MAT-\d{11}$/;
      return pattern.test(this.matterName.trim());
    },
  },
});
</script>
