import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-xxl demand-letter-container pt-4 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_component = _resolveComponent("navbar-component")!
  const _component_matter_submit = _resolveComponent("matter-submit")!
  const _component_matter_name = _resolveComponent("matter-name")!
  const _component_general_info = _resolveComponent("general-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_navbar_component),
    _createElementVNode("main", _hoisted_1, [
      (!_ctx.isGeneralInfoFetched)
        ? (_openBlock(), _createBlock(_component_matter_submit, {
            key: 0,
            onSubmit: _ctx.handleMatterSubmit
          }, null, 8, ["onSubmit"]))
        : _createCommentVNode("", true),
      _createVNode(_component_matter_name, { "matter-name": _ctx.matterName }, null, 8, ["matter-name"]),
      (_ctx.isGeneralInfoFetched)
        ? (_openBlock(), _createBlock(_component_general_info, {
            key: 1,
            matterName: _ctx.matterName,
            inputsData: _ctx.generalInfoInputs
          }, null, 8, ["matterName", "inputsData"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}