<template>
  <nav class="navbar sticky-top navbar-light bg-light">
    <div class="navbar-container container-fluid">
      <div class="navbar-heading-container align-items-center ms-3">
        <a class="navbar-brand" href="#">Demand Letter Tool</a>
      </div>

      <div class="navbar-control-container d-flex me-3">
        <div class="offcanvas offcanvas-end w-menu" data-bs-scroll="true" tabindex="-1" id="offcanvas"
             data-bs-keyboard="false" data-bs-backdrop="true">
          <div class="offcanvas-header border-bottom">
            <h6 class="offcanvas-title d-none d-sm-block fw-bold" id="offcanvas">Menu</h6>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body px-0">
            <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
              <li v-for="(button, key) in navigationButtons"
                  :key="key" class="nav-item">
                <component :is="button.component" v-bind="button.props"></component>
              </li>
            </ul>
          </div>
        </div>
        <dropdown-button button-text="New Session" :handler="handleNewSession" class="me-3"/>
        <dropdown-button button-text="Sessions List" :handler="handleSessionList" class="me-3"/>
        <button class="navbar-toggler text-dark fw-bold border-black py-2" type="button" data-bs-toggle="offcanvas"
                aria-controls="offcanvasScrolling" data-bs-target="#offcanvas" role="button">
          <span class="navbar-toggler-icon"></span>
          <i class="bi bi-arrow-right-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import DropdownMenu from "@/components/DropdownMenu.vue";
import { getNavigationButtons } from "@/common/utils";
import { defineComponent } from "vue";
import { INavigationButton } from "@/types/INavigationButton";
import DropdownButton from "@/components/DropdownButton.vue";

export default defineComponent({
  name: "NavbarComponent",

  components: {
    DropdownButton,
    DropdownMenu,
  },

  data() {
    return {
      navigationButtons: [] as INavigationButton[],
    };
  },

  methods: {
    handleNewSession() {
      this.$router.push(`/demand-letter/new`);
    },

    handleSessionList() {
      this.$router.push("/user-profile?page=1");
    },
  },

  async created() {
    this.navigationButtons = await getNavigationButtons(this.$router);
  },
});
</script>

<style scoped>
@media (min-width: 300px) {
  .w-menu {
    width: 40% !important;
  }
}

@media (min-width: 650px) {
  .w-menu {
    width: 30% !important;
  }
}

@media (min-width: 1000px) {
  .w-menu {
    width: 20% !important;
  }
}

@media (min-width: 1350px) {
  .w-menu {
    width: 15% !important;
  }
}
</style>
