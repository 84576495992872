import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      class: "me-3 my-1",
      type: "password",
      placeholder: "New Password"
    }, null, 512), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeUserPassword && _ctx.changeUserPassword(...args))),
      type: "button",
      class: "btn btn-success btn-sm mx-2"
    }, " Update "),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deactivateComponentFunc())),
      type: "button",
      class: "btn btn-danger btn-sm mx-2"
    }, " Close ")
  ], 64))
}