<template>
  <navbar-component/>
  <main class="container-fluid user-profile-container pt-4">
    <div class="row">
      <div class="col-12">
        <component :is="profileTab"/>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import NavbarComponent from "@/components/Navbar.vue";
import UserGeneralInfo from "@/components/UserGenerallnfo.vue";
import UserProfileCommonSettings from "@/components/UserProfileCommonSettings.vue";
import UserProfileAdminSettings from "@/components/UserProfileAdminSettings.vue";
import UserProfileSessionsList from "@/components/UserProfileSessionsList.vue";
import { defineUserProfileTab } from "@/common/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserProfileView",

  components: {
    UserProfileSessionsList,
    NavbarComponent,
    UserGeneralInfo,
    UserProfileCommonSettings,
    UserProfileAdminSettings,
  },

  data() {
    return {
      profileTab: {},
    };
  },

  created() {
    this.profileTab = defineUserProfileTab(this.$route);
  },

  watch: {
    "$route"() {
      this.profileTab = defineUserProfileTab(this.$route);
    },
  },
});
</script>
