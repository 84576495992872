import axios from "axios";
import {
  fetchMatterInfoUrl,
  loginUrl,
  sessionUrl,
  STATUS_CODE_UNAUTHORIZED,
  userUrl,
  validateTokenUrl
} from "@/common/constants";
import {primaryDomain} from "@/config";
import TokenService from "@/services/TokenService";
import {logout} from "@/common/utils";
import router from "@/router";
import {IGeneralInfo} from "@/types/IGeneralInfo";
import {IRetrievalRequestMessage} from "@/types/IRetrievalRequestMessage";
import {IGeneratorRequestMessage} from "@/types/IGeneratorRequestMessage";
import {IOcrRequestMessage} from "@/types/IOcrRequestMessage";
import { IUser } from "@/types/IUser";

const apiClient = axios.create({
  baseURL: primaryDomain,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = TokenService.getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === STATUS_CODE_UNAUTHORIZED) {
      console.error("Unauthorized access - redirecting to login...");
      await logout(router);
    }
    return Promise.reject(error);
  }
);

export const login = async (email: string, password: string) => {
  return apiClient.post(loginUrl, {email, password});
};

export const getUser = async () => {
  return apiClient.get<IUser>(userUrl);
};

export const createUser = async (email: string, password: string, firstName: string, lastName: string) => {
  return apiClient.post(userUrl, {email, password, firstName, lastName});
};

export const getSessions = async (params: { offset: number, limit: number }) => {
  return apiClient.get(`${sessionUrl}?offset=${params.offset}&limit=${params.limit}`);
};

export const getUsers = async () => {
  return apiClient.get(`${primaryDomain}/users`);
};

export const updateCredentials = async (email: string, password: string) => {
  return apiClient.patch(`${userUrl}/credentials`, {email, password});
};

export const updateEmail = async (uid: number, email: string) => {
  return apiClient.patch(`${userUrl}/${uid}/email`, {email});
};

export const updatePassword = async (uid: number, password: string) => {
  return apiClient.patch(`${userUrl}/${uid}/password`, {password});
};

export const deleteUser = async (uid: number) => {
  return apiClient.delete(`${userUrl}/${uid}`);
};

export const createSession = async (generalInfo: IGeneralInfo) => {
  return apiClient.post(sessionUrl, generalInfo);
};

export const getSession = async (id: number) => {
  return apiClient.get(`${sessionUrl}/${id}`);
};

export const deleteSession = async (sessionId: number) => {
  return apiClient.delete(`${sessionUrl}/${sessionId}`);
};

export const fetchMatterInfo = async (matterName: string) => {
  return apiClient.get(`${fetchMatterInfoUrl}/${matterName}`);
};

export const uploadDemandPackage = async (id: number, data: FormData) => {
  return apiClient.post(
    `${sessionUrl}/${id}/demand-package`,
    data,
    {headers: {"Content-Type": "multipart/form-data"}}
  );
};

export const reprocessOcr = async (ocrRequestMessage: IOcrRequestMessage) => {
  return apiClient.post(`${sessionUrl}/${ocrRequestMessage.id}/ocr`, {...ocrRequestMessage});
};

export const reprocessRetrieval = async (retrievalRequestMessage: IRetrievalRequestMessage) => {
  return apiClient.post(`${sessionUrl}/${retrievalRequestMessage.id}/retrieval`, {...retrievalRequestMessage});
};

export const reprocessGenerator = async (generatorRequestMessage: IGeneratorRequestMessage) => {
  return apiClient.post(`${sessionUrl}/${generatorRequestMessage.id}/generator`, {...generatorRequestMessage});
};

export const validateToken = async (token: string) => {
  return apiClient.post(`${validateTokenUrl}`, {token});
};
