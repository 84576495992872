<template>
  <div class="container user-general-info-container">
    <h4 class="text-start fw-bold"> User general info </h4>
    <div class="row">
      <div class="col-md-12">
        <div class="text-start border border-success border-opacity-75 border-5 rounded p-3">
          <p v-if="email" class="user-email fs-4 fw-medium mb-0">Email: {{ email }}</p>
          <p v-if="firstName" class="fs-4 fw-medium mb-0">First Name: {{ firstName }}</p>
          <p v-if="lastName" class=" fs-4 fw-medium mb-0">Last Name: {{ lastName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserGeneralInfo",

  props: {
    email: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
});
</script>
