<template>
  <div class="dropdown">
    <button :class="classes" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{ text }}
    </button>
    <ul class="dropdown-menu">
      <li v-for="(button, key) in dropdownButtons"
          :key="key">
        <component :is="button.component" v-bind="button.props"></component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "DropdownMenu",

  props: {
    text: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    dropdownButtons: {
      type: Array as PropType<Array<{ component: string | Object, props: Record<string, any> }>>,
    },
  },
});
</script>

<style scoped>
.dropdown-menu {
  --bs-dropdown-link-active-bg: #42b983 !important;
}
</style>
