<template>
  <div class="container">
    <div v-if="loading" class="spinner-border" role="status"></div>
    <div v-else-if="sessionList.length !== 0">
      <h2 class="mb-3">Demand Letter List</h2>
      <table class="table container table-bordered table-hover table-striped-columns">
        <thead class="table-success">
        <tr>
          <th scope="col">Matter Name</th>
          <th scope="col">Status</th>
          <th scope="col">Client</th>
          <th scope="col">Creation date</th>
          <th scope="col">Update date</th>
          <th scope="col">Link</th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr v-for="(session) in sessionList" :key="session.id" class="align-items-center">
          <td class="text-nowrap">{{ session.generalInfo.matterInfo.matterName }}</td>
          <td>{{ formatStatus(session.status) }}</td>
          <td>{{ session.generalInfo.matterInfo.clientName }}</td>
          <td>{{ formatDate(session.createdAt) }}</td>
          <td>{{ formatDate(session.updatedAt) }}</td>
          <td class="align-middle">
            <div v-if="confirmingDeletion && sessionToDelete === session.id">
              <p class="mb-1">Delete this session?</p>

              <button @click="confirmDeleteSession(session.id)" type="button" class="btn btn-danger btn-sm me-1">Delete
              </button>
              <button @click="cancelDelete()" type="button" class="btn btn-success btn-sm">Cancel</button>
            </div>
            <div v-else class="d-flex justify-content-between">
              <router-link :to="`/demand-letter?session-id=${session.id}`">
                <span>View 👀</span>
              </router-link>

              <button @click="promptDeleteSession(session.id)" type="button" class="btn-close"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage <= 1 }">
            <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage - 1)">Previous</a>
          </li>
          <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="handlePageChange(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage >= totalPages }">
            <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>
    </div>
    <p v-else class="fw-bold fst-italic mt-5">
      There are no sessions have been started yet.
    </p>
  </div>
</template>

<script lang="ts">
import { deleteSession, getSessions } from "@/common/api";
import { getMappedStatus } from "@/common/utils";
import { RESEND_REQUEST_TIMEOUT, StatusKey } from "@/common/constants";
import { defineComponent } from "vue";
import { ISession } from "@/types/ISession";
import { AxiosError } from "axios";

export default defineComponent({
  name: "UserProfileSessionsList",

  data() {
    return {
      sessionList: [] as ISession[],
      intervalRequest: null as ReturnType<typeof setTimeout> | null,
      loading: true,
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 25,
      totalItems: 0,
      sessionToDelete: 0,
      confirmingDeletion: false,
    };
  },

  methods: {
    async fetchSessions(page = 1, showLoading = true) {
      if (showLoading) {
        this.loading = true;
      }

      if (page < 1) {
        this.handlePageChange(1);
        return;
      }

      this.currentPage = page;
      const offset = (this.currentPage - 1) * this.itemsPerPage;

      try {
        const response = await getSessions({
          offset: offset,
          limit: this.itemsPerPage
        });
        this.sessionList = response.data.sessions;
        this.totalItems = response.data.totalItems;
      } catch (error) {
        const message = "Failed to get sessions data:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }

      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

      if (page > this.totalPages) {
        this.handlePageChange(this.totalPages);
        this.loading = false;
        return;
      }

      if (this.sessionList.length === 0 && this.currentPage > 1) {
        this.handlePageChange(this.currentPage - 1);
      }

      this.loading = false;
    },

    handlePageChange(page: number) {
      if (page > 0 && page <= this.totalPages) {
        this.$router.push({path: "/user-profile", query: {page}});
        this.fetchSessions(page);
      }
    },

    async setupIntervalRequest(interval: number) {
      this.intervalRequest = setInterval(async () => {
        await this.fetchSessions(this.currentPage, false);
      }, interval);
    },

    async rerenderSessionList() {
      await this.fetchSessions(this.currentPage, false);
    },

    formatStatus(status: StatusKey) {
      return getMappedStatus(status);
    },

    formatDate(utcString: string | null) {
      if (!utcString) {
        return "";
      }

      const date = new Date(utcString);

      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return `${formattedDate} ${formattedTime}`;
    },

    promptDeleteSession(sessionId: number) {
      if (this.sessionToDelete !== sessionId) {
        this.cancelDelete();
      }

      this.sessionToDelete = sessionId;
      this.confirmingDeletion = true;
    },

    async confirmDeleteSession(sessionId: number) {
      try {
        await deleteSession(sessionId);
      } catch (error) {
        const message = "Failed to delete session:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }

      this.cancelDelete();
      await this.rerenderSessionList();
    },

    cancelDelete() {
      this.sessionToDelete = 0;
      this.confirmingDeletion = false;
    },
  },

  async created() {
    const pageStr = String(this.$route.query);
    const page = parseInt(pageStr) || 1;
    await this.fetchSessions(page);
    await this.setupIntervalRequest(RESEND_REQUEST_TIMEOUT);
  },

  beforeUnmount() {
    if (this.intervalRequest) {
      clearInterval(this.intervalRequest);
    }
  },
});
</script>
