<template>
  <div class="container general-info-container mb-3">
    <div :class="{'border': sessionId}"
         class="border-success border-opacity-75 border-5 rounded p-3">
      <h5 class="text-start fw-bold mb-3"> General information about the case:</h5>

      <form id="generalInfoForm" class="d-flex flex-wrap ">
        <div
            v-for="(_, key) in localInputsData"
            :key="key"
            class="d-flex flex-column pe-5 mb-3 col-12 col-sm-12 col-md-5 col-lg-6 col-xl-4 col-xxl-4">
          <label class="p-2 ps-0 fs-5 text-start fw-bold" :for="key">
            {{ key }}
          </label>
          <input
              v-model="localInputsData[key]"
              class="p-2 fw-bold"
              type="text"
              :disabled="Boolean(sessionId)"
              placeholder="none"
              :id="key"/>
        </div>
      </form>

      <div v-if="!sessionId" class=" mt-4 d-flex align-items-center">
        <h5 class="text-start fw-bold ps-0 mb-0">Make sure all the information is correct and click "Continue".</h5>
        <button form="generalInfoForm" v-if="!loading" @click="submitGeneralInfo"
                class="btn btn-secondary fw-bold fs-6 col-2 ms-5">Continue
        </button>
        <button v-else class="btn btn-secondary fw-bold ms-5 col-2" type="button" disabled>
          <span class="spinner-border spinner-border-sm me-3" aria-hidden="true"></span>
          <span role="status">Loading...</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { createSession } from "@/common/api";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  name: "GeneralInfo",

  props: {
    matterName: {
      type: String,
      required: true,
    },
    inputsData: {
      type: Object,
      required: true,
    },
    sessionId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      error: {} as Error | null,
      localInputsData: {},
    };
  },

  methods: {
    async submitGeneralInfo() {
      const generalInfo = {
        matterInfo: {
          matterName: String(this.matterName),
          email: String(this.inputsData["Email"]),
          insuranceCompanyName: String(this.inputsData["Insurance Company Name"]),
          address: String(this.inputsData["Address"]),
          adjusterName: String(this.inputsData["Adjuster Name"]),
          adjusterSalutation: String(this.inputsData["Adjuster Salutation"]),
          phone: String(this.inputsData["Phone"]),
          clientName: String(this.inputsData["Client Name"]),
          defendantName: String(this.inputsData["Defendant Name"]),
          claimNumber: String(this.inputsData["Claim Number"]),
          incidentDate: String(this.inputsData["Incident Date"]),
          affordedInPip: Number(this.inputsData["Afforded in PIP"]),
        },
        damage: Number(this.inputsData["Damage"]),
      };

      this.loading = true;
      this.error = null;

      try {
        const response = await createSession(generalInfo);

        if (response.status === 201) {
          this.loading = false;
          this.$router.push(`/demand-letter?session-id=${response.data.id}`);
        }
      } catch (error) {
        const message = "Failed to create session:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          this.error = error.response?.data.detail;
          console.error(message, error.response?.data)
        }
      }

      this.loading = false;
    },
  },

  mounted() {
    this.localInputsData = this.inputsData;
  },
});
</script>

<style scoped>
form label {
  display: inline-block;
  height: 2em;
}
</style>
