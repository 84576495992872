<template>
  <button @click="handler" class="nav-link text-truncate ms-1 d-none d-sm-inline fw-bold" :class="buttonClasses"
          type="button">
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "DropdownButton",

  props: {
    handler: {
      type: Function as PropType<() => void>,
      required: true,
    },
    buttonClasses: {
      type: String,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
});
</script>
