<template>
  <td v-if="!activeComponent">
    <button @click="selectAction('AdminTableChangeEmailButton'); setError(null);" type="button"
            class="btn btn-success btn-sm">Change Email
    </button>
    <span class="border-start border-secondary mx-2"></span>
    <button @click="selectAction('AdminTableChangePasswordButton'); setError(null);" type="button"
            class="btn btn-success btn-sm">Change Password
    </button>
    <span class="border-start border-secondary mx-2"></span>
    <button @click="selectAction('AdminTableDeleteUserButton'); setError(null);" type="button"
            class="btn btn-danger btn-sm">Delete User
    </button>
  </td>

  <td v-else>
    <component
        :is="activeComponent"
        :setError="setError"
        :recordId="recordId"
        :deactivateComponentFunc="deactivateAction"
        :recreateTableFunc="recreateTableFunc">
    </component>
  </td>
</template>

<script lang="ts">
import AdminTableChangePasswordButton from "@/components/AdminTableChangePasswordButton.vue";
import AdminTableChangeEmailButton from "@/components/AdminTableChangeEmailButton.vue";
import AdminTableDeleteUserButton from "@/components/AdminTableDeleteUserButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdminTableActionButtons",

  components: {
    AdminTableChangePasswordButton,
    AdminTableChangeEmailButton,
    AdminTableDeleteUserButton,
  },

  props: {
    recordId: {
      type: Number,
      required: true,
    },
    recreateTableFunc: {
      type: Function,
      required: true,
    },
    setError: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      activeComponent: null as (string | null),
    };
  },

  methods: {
    async selectAction(actionName: string | null) {
      this.activeComponent = actionName;
    },

    async deactivateAction() {
      this.activeComponent = null;
    },
  },
});
</script>
