import { createRouter, createWebHistory, RouteLocationNormalized } from "vue-router"
import LoginView from "@/views/LoginView.vue";
import DemandLetterView from "@/views/DemandLetterView.vue";
import UserProfileView from "@/views/UserProfileView.vue";
import NewSession from "@/views/NewSession.vue";
import TokenService from "@/services/TokenService";
import { logout } from "@/common/utils";

const routes = [
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/user-profile",
    name: "UserProfileView",
    component: UserProfileView,
    meta: {requiresAuth: true},
  },
  {
    path: "/demand-letter/new",
    name: "NewSession",
    component: NewSession,
    meta: {requiresAuth: true},
  },
  {
    path: "/demand-letter",
    name: "DemandLetterView",
    component: DemandLetterView,
    meta: {requiresAuth: true},
    props: (route: RouteLocationNormalized) => ({sessionId: Number(route.query["session-id"])}),
  },
  // When any page is accessed it's then redirected to the main protected route.
  {
    path: "/:pathMatch(.*)*",
    redirect: "/user-profile?page=1",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Manage navigation.
router.beforeEach(async (to, from, next) => {
  const isProtectedRoute = to.matched.some(route => route.meta.requiresAuth);
  if (isProtectedRoute) {
    const isValid = await TokenService.validateToken();
    if (isValid) {
      next();
    } else {
      await logout(router);
    }
  } else {
    next();
  }
});

export default router;
