import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-border",
  role: "status"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "table container table-bordered table-hover table-striped-columns" }
const _hoisted_5 = { class: "table-group-divider" }
const _hoisted_6 = { class: "text-nowrap" }
const _hoisted_7 = { class: "align-middle" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 1,
  class: "d-flex justify-content-between"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { "aria-label": "Page navigation" }
const _hoisted_13 = { class: "pagination justify-content-center" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 2,
  class: "fw-bold fst-italic mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_ctx.sessionList.length !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "mb-3" }, "Demand Letter List", -1)),
            _createElementVNode("table", _hoisted_4, [
              _cache[5] || (_cache[5] = _createElementVNode("thead", { class: "table-success" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { scope: "col" }, "Matter Name"),
                  _createElementVNode("th", { scope: "col" }, "Status"),
                  _createElementVNode("th", { scope: "col" }, "Client"),
                  _createElementVNode("th", { scope: "col" }, "Creation date"),
                  _createElementVNode("th", { scope: "col" }, "Update date"),
                  _createElementVNode("th", { scope: "col" }, "Link")
                ])
              ], -1)),
              _createElementVNode("tbody", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessionList, (session) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: session.id,
                    class: "align-items-center"
                  }, [
                    _createElementVNode("td", _hoisted_6, _toDisplayString(session.generalInfo.matterInfo.matterName), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatStatus(session.status)), 1),
                    _createElementVNode("td", null, _toDisplayString(session.generalInfo.matterInfo.clientName), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(session.createdAt)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(session.updatedAt)), 1),
                    _createElementVNode("td", _hoisted_7, [
                      (_ctx.confirmingDeletion && _ctx.sessionToDelete === session.id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mb-1" }, "Delete this session?", -1)),
                            _createElementVNode("button", {
                              onClick: ($event: any) => (_ctx.confirmDeleteSession(session.id)),
                              type: "button",
                              class: "btn btn-danger btn-sm me-1"
                            }, "Delete ", 8, _hoisted_9),
                            _createElementVNode("button", {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelDelete())),
                              type: "button",
                              class: "btn btn-success btn-sm"
                            }, "Cancel")
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_router_link, {
                              to: `/demand-letter?session-id=${session.id}`
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createElementVNode("span", null, "View 👀", -1)
                              ])),
                              _: 2
                            }, 1032, ["to"]),
                            _createElementVNode("button", {
                              onClick: ($event: any) => (_ctx.promptDeleteSession(session.id)),
                              type: "button",
                              class: "btn-close"
                            }, null, 8, _hoisted_11)
                          ]))
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("nav", _hoisted_12, [
              _createElementVNode("ul", _hoisted_13, [
                _createElementVNode("li", {
                  class: _normalizeClass(["page-item", { disabled: _ctx.currentPage <= 1 }])
                }, [
                  _createElementVNode("a", {
                    class: "page-link",
                    href: "#",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handlePageChange(_ctx.currentPage - 1)), ["prevent"]))
                  }, "Previous")
                ], 2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (page) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: page,
                    class: _normalizeClass(["page-item", { active: _ctx.currentPage === page }])
                  }, [
                    _createElementVNode("a", {
                      class: "page-link",
                      href: "#",
                      onClick: _withModifiers(($event: any) => (_ctx.handlePageChange(page)), ["prevent"])
                    }, _toDisplayString(page), 9, _hoisted_14)
                  ], 2))
                }), 128)),
                _createElementVNode("li", {
                  class: _normalizeClass(["page-item", { disabled: _ctx.currentPage >= _ctx.totalPages }])
                }, [
                  _createElementVNode("a", {
                    class: "page-link",
                    href: "#",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handlePageChange(_ctx.currentPage + 1)), ["prevent"]))
                  }, "Next")
                ], 2)
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_15, " There are no sessions have been started yet. "))
  ]))
}