import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.classes),
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, _toDisplayString(_ctx.text), 3),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownButtons, (button, key) => {
        return (_openBlock(), _createElementBlock("li", { key: key }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(button.component), _mergeProps({ ref_for: true }, button.props), null, 16))
        ]))
      }), 128))
    ])
  ]))
}