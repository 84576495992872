<template>
  <!-- @TODO: Display status_text instead instead of hardcoded text?   -->
  <div class="container process-demand-letter-container">

    <!-- Upload form -->
    <div v-if="status === GENERAL_INFO_STATUS()" class="upload-form-container mb-3">
      <h3>Upload a file</h3>
      <p class="help-text fw-bold">
        Please attach the demand-package in PDF format and then click "Start Processing".
      </p>

      <!-- Submit PDF form -->
      <form
          @submit.prevent="startDemandLetterGeneration"
          class="border border-success border-opacity-75 border-5 rounded d-flex flex-column flex-lg-row
                 justify-content-between align-items-center p-3">

        <!-- File Upload -->
        <label class="fw-bold me-1" for="pdfFile">Upload PDF File:</label>
        <input class="fw-bold me-auto" required type="file" id="pdfFile" accept=".pdf" @change="setFileToState"/>

        <!-- Submit Button -->
        <button class="btn btn-secondary fw-bold"
                type="submit"
                :disabled="!selectedFile">
          Start Processing
        </button>
      </form>
    </div>

    <div v-if="status === COMPLETED_STATUS() && !loading">
      <div class="my-3">
        <p class="h2 m-3">Generated Demand Letter</p>
        <div class="mb-3">
          <div class="border border-success border-opacity-75 border-5 rounded">
            <Markdown :source="demandLetter" class="text-start p-3"/>
          </div>
        </div>

        <div
            class="d-flex flex-column align-items-start gap-3 border border-success border-opacity-75 border-5 rounded p-3 mb-3 ">
          <h2>Generator Data</h2>
          <textarea v-model="generator" class="textarea w-100"/>
          <button class="btn btn-secondary fw-bold align-self-end" @click="handleUpdateGenerator">Update generator
            data
          </button>
        </div>

        <div
            class="d-flex flex-column align-items-start gap-3 mb-3 border border-success border-opacity-75 border-5 rounded p-3">
          <h2>Retrieval Data</h2>

          <h3>Facts</h3>
          <textarea v-model="facts" class="textarea w-100"/>

          <h3>Liability</h3>
          <textarea v-model="liability" class="textarea w-100"/>

          <h3>Injuries Medicals</h3>
          <textarea v-model="injuriesMedicals" class="textarea w-100"/>
          <button class="btn btn-secondary fw-bold align-self-end" @click="handleUpdateRetrieval">Reprocess with the new
            retrieval data
          </button>
        </div>

        <div
            class="d-flex flex-column align-items-start gap-3 border border-success border-opacity-75 border-5 rounded p-3">
          <h2 class="align-self-start">OCR Data</h2>
          <textarea v-model="ocr" class="textarea w-100"/>
          <button class="btn btn-secondary fw-bold align-self-end" @click="handleUpdateOcr">Reprocess with the new OCR
            data
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isProgressComplete" class="fw-bold fs-5">
      {{ progressText }}
      <div class="progress my-3" style="width: 100%;">
        <div class="progress-bar"
             role="progressbar"
             :style="{ width: progress + '%'}"
             :aria-valuenow="progress"
             aria-valuemin="0"
             aria-valuemax="100">
        </div>
      </div>
    </div>

    <div v-if="status !== COMPLETED_STATUS() && status !== GENERAL_INFO_STATUS() && isProgressComplete"
         class="processing-status-container d-flex align-items-center flex-md-column">
      <div class="spinner-container">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
        <div class="fw-bold fs-5 mb-3">
          <div class="mb-3">We are processing your documents. Please wait...</div>
          <div>Stage: {{ formatStatus(status) }}. ETA: 5 minutes</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Markdown from "vue3-markdown-it";
import { getMappedStatus } from "@/common/utils";
import { reprocessGenerator, reprocessOcr, reprocessRetrieval, uploadDemandPackage } from "@/common/api";
import { COMPLETED_STATUS, GENERAL_INFO_STATUS, RESEND_REQUEST_TIMEOUT, StatusKey } from "@/common/constants";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  components: {
    Markdown,
  },

  props: {
    status: {
      type: String,
      required: true,
    },
    sessionData: {
      type: Object,
      required: true,
    },
    demandLetter: {
      type: String,
      required: true,
    },
    handleFetchSessionInfo: {
      type: Function,
      required: true,
    },
    salutation: {
      type: String,
    },
    sessionId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      selectedFile: {} as File | undefined,
      ocr: "",
      facts: "",
      liability: "",
      injuriesMedicals: "",
      generator: "",
      progress: 0,
      progressStartTime: 0,
      intervalRequest: null as ReturnType<typeof setTimeout> | null,
      isProgressComplete: true,
      progressText: "Downloading your documents...",
    };
  },

  methods: {
    GENERAL_INFO_STATUS() {
      return GENERAL_INFO_STATUS;
    },

    COMPLETED_STATUS() {
      return COMPLETED_STATUS;
    },

    async startDemandLetterGeneration() {
      this.loading = true;

      this.startProgress();

      const formData = new FormData();
      if (this.selectedFile instanceof File) {
        formData.append("demand_package", this.selectedFile);
      }

      try {
        await uploadDemandPackage(this.sessionId, formData);
        await this.handleFetchSessionInfo(this.sessionId);
      } catch (error) {
        const message = "Failed to upload demand package:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      } finally {
        this.loading = false;
      }
    },

    startProgress() {
      this.progressStartTime = Date.now();
      localStorage.setItem(`progressStartTime-${this.sessionId}`, String(this.progressStartTime));
      this.updateProgress();
    },

    updateProgress() {
      this.isProgressComplete = false;

      const firstPhaseDuration = 50000;
      const secondPhaseDuration = 10000;
      const midPoint = 60;

      const updateProgress = () => {
        const elapsedTime = Date.now() - this.progressStartTime;

        if (elapsedTime <= firstPhaseDuration) {
          this.progress = (elapsedTime / firstPhaseDuration) * midPoint;
        } else {
          const timeInSecondPhase = elapsedTime - firstPhaseDuration;
          this.progress = midPoint + (timeInSecondPhase / secondPhaseDuration) * (100 - midPoint);
        }

        if (this.progress < 100) {
          requestAnimationFrame(updateProgress);
        } else {
          this.loading = false;
          localStorage.removeItem(`progressStartTime-${this.sessionId}`);

          this.progressText = "Done!";
          setTimeout(() => {
            this.isProgressComplete = true;
          }, 3000);
        }
      };

      requestAnimationFrame(updateProgress);
    },

    setFileToState(event: Event) {
      this.selectedFile = (event.target as HTMLInputElement).files?.[0];
    },

    async handleUpdateOcr() {
      try {
        const response = await reprocessOcr({id: this.sessionId, ocr: this.ocr});

        if (response.status === 200) {
          this.loading = false;
          await this.handleFetchSessionInfo();
        }
      } catch (error) {
        const message = "Failed to reprocess OCR:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },

    async handleUpdateRetrieval() {
      const retrievalMessage = {
        id: this.sessionId,
        facts: this.facts,
        liability: this.liability,
        injuriesMedicals: this.injuriesMedicals
      }

      try {
        const response = await reprocessRetrieval(retrievalMessage);

        if (response.status === 200) {
          this.loading = false;
          await this.handleFetchSessionInfo();
        }
      } catch (error) {
        const message = "Failed to reprocess retrieval:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },

    async handleUpdateGenerator() {
      try {
        const response = await reprocessGenerator({id: this.sessionId, demandLetter: this.generator});

        if (response.status === 200) {
          this.loading = false;
          await this.handleFetchSessionInfo();
        }
      } catch (error) {
        const message = "Failed to reprocess generator:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },

    async setupIntervalRequest(interval: number) {
      this.intervalRequest = setInterval(async () => {
        await this.handleFetchSessionInfo();
      }, interval);
    },

    formatStatus(status: StatusKey) {
      return getMappedStatus(status);
    }
  },

  async created() {
    await this.setupIntervalRequest(RESEND_REQUEST_TIMEOUT);

    this.ocr = this.sessionData.ocr;
    this.facts = this.sessionData.facts;
    this.liability = this.sessionData.liability;
    this.injuriesMedicals = this.sessionData.injuriesMedicals;
    this.generator = this.sessionData.demandLetter;
  },

  mounted() {
    const savedStartTime = localStorage.getItem(`progressStartTime-${this.sessionId}`);

    if (savedStartTime) {
      this.progressStartTime = parseInt(savedStartTime, 10);
      this.updateProgress();
    }
  },

  beforeUnmount() {
    if (this.intervalRequest) {
      clearInterval(this.intervalRequest);
    }
  },
});
</script>

<style scoped>
.textarea {
  height: 200px;
}
</style>
