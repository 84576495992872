import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container process-demand-letter-container" }
const _hoisted_2 = {
  key: 0,
  class: "upload-form-container mb-3"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "my-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "border border-success border-opacity-75 border-5 rounded" }
const _hoisted_8 = { class: "d-flex flex-column align-items-start gap-3 border border-success border-opacity-75 border-5 rounded p-3 mb-3" }
const _hoisted_9 = { class: "d-flex flex-column align-items-start gap-3 mb-3 border border-success border-opacity-75 border-5 rounded p-3" }
const _hoisted_10 = { class: "d-flex flex-column align-items-start gap-3 border border-success border-opacity-75 border-5 rounded p-3" }
const _hoisted_11 = {
  key: 2,
  class: "fw-bold fs-5"
}
const _hoisted_12 = {
  class: "progress my-3",
  style: {"width":"100%"}
}
const _hoisted_13 = ["aria-valuenow"]
const _hoisted_14 = {
  key: 3,
  class: "processing-status-container d-flex align-items-center flex-md-column"
}
const _hoisted_15 = { class: "spinner-container" }
const _hoisted_16 = { class: "fw-bold fs-5 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === _ctx.GENERAL_INFO_STATUS())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Upload a file", -1)),
          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "help-text fw-bold" }, " Please attach the demand-package in PDF format and then click \"Start Processing\". ", -1)),
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.startDemandLetterGeneration && _ctx.startDemandLetterGeneration(...args)), ["prevent"])),
            class: "border border-success border-opacity-75 border-5 rounded d-flex flex-column flex-lg-row justify-content-between align-items-center p-3"
          }, [
            _cache[10] || (_cache[10] = _createElementVNode("label", {
              class: "fw-bold me-1",
              for: "pdfFile"
            }, "Upload PDF File:", -1)),
            _createElementVNode("input", {
              class: "fw-bold me-auto",
              required: "",
              type: "file",
              id: "pdfFile",
              accept: ".pdf",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setFileToState && _ctx.setFileToState(...args)))
            }, null, 32),
            _createElementVNode("button", {
              class: "btn btn-secondary fw-bold",
              type: "submit",
              disabled: !_ctx.selectedFile
            }, " Start Processing ", 8, _hoisted_3)
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === _ctx.COMPLETED_STATUS() && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[19] || (_cache[19] = _createElementVNode("p", { class: "h2 m-3" }, "Generated Demand Letter", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Markdown, {
                  source: _ctx.demandLetter,
                  class: "text-start p-3"
                }, null, 8, ["source"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Generator Data", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.generator) = $event)),
                class: "textarea w-100"
              }, null, 512), [
                [_vModelText, _ctx.generator]
              ]),
              _createElementVNode("button", {
                class: "btn btn-secondary fw-bold align-self-end",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateGenerator && _ctx.handleUpdateGenerator(...args)))
              }, "Update generator data ")
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[14] || (_cache[14] = _createElementVNode("h2", null, "Retrieval Data", -1)),
              _cache[15] || (_cache[15] = _createElementVNode("h3", null, "Facts", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.facts) = $event)),
                class: "textarea w-100"
              }, null, 512), [
                [_vModelText, _ctx.facts]
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("h3", null, "Liability", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.liability) = $event)),
                class: "textarea w-100"
              }, null, 512), [
                [_vModelText, _ctx.liability]
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Injuries Medicals", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.injuriesMedicals) = $event)),
                class: "textarea w-100"
              }, null, 512), [
                [_vModelText, _ctx.injuriesMedicals]
              ]),
              _createElementVNode("button", {
                class: "btn btn-secondary fw-bold align-self-end",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateRetrieval && _ctx.handleUpdateRetrieval(...args)))
              }, "Reprocess with the new retrieval data ")
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[18] || (_cache[18] = _createElementVNode("h2", { class: "align-self-start" }, "OCR Data", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ocr) = $event)),
                class: "textarea w-100"
              }, null, 512), [
                [_vModelText, _ctx.ocr]
              ]),
              _createElementVNode("button", {
                class: "btn btn-secondary fw-bold align-self-end",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateOcr && _ctx.handleUpdateOcr(...args)))
              }, "Reprocess with the new OCR data ")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isProgressComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createTextVNode(_toDisplayString(_ctx.progressText) + " ", 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: "progress-bar",
              role: "progressbar",
              style: _normalizeStyle({ width: _ctx.progress + '%'}),
              "aria-valuenow": _ctx.progress,
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, null, 12, _hoisted_13)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status !== _ctx.COMPLETED_STATUS() && _ctx.status !== _ctx.GENERAL_INFO_STATUS() && _ctx.isProgressComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[21] || (_cache[21] = _createElementVNode("div", {
              class: "spinner-border",
              style: {"width":"3rem","height":"3rem"},
              role: "status"
            }, null, -1)),
            _createElementVNode("div", _hoisted_16, [
              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mb-3" }, "We are processing your documents. Please wait...", -1)),
              _createElementVNode("div", null, "Stage: " + _toDisplayString(_ctx.formatStatus(_ctx.status)) + ". ETA: 5 minutes", 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}