import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handler && _ctx.handler(...args))),
    class: _normalizeClass(["nav-link text-truncate ms-1 d-none d-sm-inline fw-bold", _ctx.buttonClasses]),
    type: "button"
  }, _toDisplayString(_ctx.buttonText), 3))
}