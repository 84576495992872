import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container general-info-container mb-3" }
const _hoisted_2 = {
  id: "generalInfoForm",
  class: "d-flex flex-wrap"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["onUpdate:modelValue", "disabled", "id"]
const _hoisted_5 = {
  key: 0,
  class: "mt-4 d-flex align-items-center"
}
const _hoisted_6 = {
  key: 1,
  class: "btn btn-secondary fw-bold ms-5 col-2",
  type: "button",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{'border': _ctx.sessionId}, "border-success border-opacity-75 border-5 rounded p-3"])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "text-start fw-bold mb-3" }, " General information about the case:", -1)),
      _createElementVNode("form", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localInputsData, (_, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: "d-flex flex-column pe-5 mb-3 col-12 col-sm-12 col-md-5 col-lg-6 col-xl-4 col-xxl-4"
          }, [
            _createElementVNode("label", {
              class: "p-2 ps-0 fs-5 text-start fw-bold",
              for: key
            }, _toDisplayString(key), 9, _hoisted_3),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": ($event: any) => ((_ctx.localInputsData[key]) = $event),
              class: "p-2 fw-bold",
              type: "text",
              disabled: Boolean(_ctx.sessionId),
              placeholder: "none",
              id: key
            }, null, 8, _hoisted_4), [
              [_vModelText, _ctx.localInputsData[key]]
            ])
          ]))
        }), 128))
      ]),
      (!_ctx.sessionId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "text-start fw-bold ps-0 mb-0" }, "Make sure all the information is correct and click \"Continue\".", -1)),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  form: "generalInfoForm",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitGeneralInfo && _ctx.submitGeneralInfo(...args))),
                  class: "btn btn-secondary fw-bold fs-6 col-2 ms-5"
                }, "Continue "))
              : (_openBlock(), _createElementBlock("button", _hoisted_6, _cache[1] || (_cache[1] = [
                  _createElementVNode("span", {
                    class: "spinner-border spinner-border-sm me-3",
                    "aria-hidden": "true"
                  }, null, -1),
                  _createElementVNode("span", { role: "status" }, "Loading...", -1)
                ])))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}