<template>
  <div class="container text-start">
    <div class="row align-items-start">
      <div class="col-12 my-3"><h4 class="text-start">Add new User</h4></div>
    </div>

    <div v-if="error" class="container text-danger fw-bold text-start my-1">
      {{ error }}
      <button @click="error = null" type="button" class="btn-close btn-danger pt-0"></button>
    </div>

    <form @submit.prevent="createUserRequest">
      <div class="mb-3 col-3">
        <label for="exampleInputEmail1" class="form-label fw-bold">Email address</label>
        <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
               placeholder="Email" required>
      </div>
      <div class="mb-3 col-3">
        <label for="exampleInputEmail1" class="form-label fw-bold">First Name</label>
        <input v-model="firstName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
               placeholder="First Name" required>
      </div>
      <div class="mb-3 col-3">
        <label for="exampleInputEmail1" class="form-label fw-bold">Last Name</label>
        <input v-model="lastName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
               placeholder="Last Name" required>
      </div>
      <div class="mb-3 col-3">
        <label for="exampleInputPassword1" class="form-label fw-bold">Password</label>
        <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
               required>
      </div>

      <div class="col-3 d-flex justify-content-between">
        <button type="submit" class="btn btn-success">Add User</button>
        <button @click="selectUserTable" type="button" class="btn btn-danger ">Back to Active Users</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { createUser } from "@/common/api";
import {defineComponent, PropType} from "vue";
import {AxiosError} from "axios";

export default defineComponent({
  name: "AdminAddNewUser",

  props: {
    selectUserTable: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  data() {
    return {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      error: null,
    };
  },

  methods: {
    async createUserRequest() {
      try {
        await createUser(this.email, this.password, this.firstName, this.lastName);
        this.selectUserTable();
      } catch (error) {
        const message = "Failed to create user:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },
  },
});
</script>
