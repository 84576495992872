<!-- @TODO: It is possible to increase scalability by implementing a BaseSettings component with shared functionality and extending it.
 for specific settings. e.g. Delete User is a shared setting and can be placed into superclass-->
<template>
  <div class="container">
    <h2 class="mt-3 mb-5">Admin Settings</h2>

    <component
        :is="activeComponent"
        :selectUserTable="selectUserTable"
        :selectAddUser="selectAddUser">
    </component>
  </div>
</template>

<script lang="ts">
import AdminTable from "@/components/AdminTable.vue";
import AdminAddNewUser from "@/components/AdminAddNewUser.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserProfileAdminSettings",

  data() {
    return {
      activeComponent: AdminTable as any,
    };
  },

  methods: {
    async selectUserTable() {
      this.activeComponent = AdminTable;
    },
    async selectAddUser() {
      this.activeComponent = AdminAddNewUser;
    }
  },
});
</script>
